import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../common'
import { Modal } from 'react-bootstrap'
import { useFormFields } from '../../../helpers/hooksFormInput'
import moment from 'moment';
import { loadAllTovReports, sendTovEmailAction } from '../../../stores/actions/admin/TovActions'
import { Typography } from '@mui/material'

const TovData = () => {
  const dispatch = useDispatch()

  const d = new Date()
  const crntMonth = d.getMonth()
  const crntYear = d.getFullYear()
  const preYear = crntYear - 1
  const nxtYear = crntYear + 1
  var option1, option2, option1Value, option2Value
  if (crntMonth >= 4 && crntMonth <= 9) {
    option1 = `01-May-${crntYear} to 31-Oct-${crntYear}`
    option2 = `01-Nov-${preYear} to 30-Apr-${crntYear}`
    option1Value = crntYear + '-05-01 to ' + crntYear + '-10-31'
    option2Value = preYear + '-11-01 to ' + crntYear + '-04-30'
  } else if (crntMonth < 4) {
    option1 = `01-Nov-${preYear} to 30-Apr-${crntYear}`
    option2 = `01-May-${preYear} to 31-Oct-${preYear}`
    option1Value = preYear + '-11-01 to ' + crntYear + '-04-30'
    option2Value = preYear + '-05-01 to ' + preYear + '-10-31'
  } else {
    option1 = `01-Nov-${crntYear} to 30-Apr-${nxtYear}`
    option2 = `01-May-${crntYear} to 31-Oct-${crntYear}`
    option1Value = crntYear + '-11-01 to ' + nxtYear + '-04-30'
    option2Value = crntYear + '-05-01 to ' + crntYear + '-10-31'
  }

  let columns = [
    {
      dataField: 'event_id',
      text: 'Event ID',
      sort: true
    },
    {
      dataField: 'event_date',
      text: 'Event Date',
      sort: true
    },
    {
      dataField: 'event_name',
      text: 'Event Name',
      sort: true
    },
    {
      dataField: 'hcp_name',
      text: 'HCP Name',
      sort: true
    },
    {
      dataField: 'hcp_paidto',
      text: 'Paid To',
      sort: true
    },
    {
      dataField: 'tov_registration',
      text: 'Registration',
      sort: true
    },
    {
      dataField: 'hcp_travel_accomodation',
      text: 'Travel and Accomodation',
      sort: true
    },
    {
      dataField: 'tov_honorarium',
      text: 'Honorarium',
      sort: true
    }
  ]

  const showLabel = () => {
    let expiryDate = fields.tov_date_range.split("to");
    console.log(expiryDate);
    let result = moment(expiryDate[1]).format('YYYY-MM-DD')
    return moment(result).isBefore('2024-05-01');
  }

  const [tovs, setTovs] = useState([])
  const [tovEmailModal, setTovEmailModal] = useState(false)
  const [dateRange, setDateRange] = useState(null)

  const loadTovs = useSelector(state => state.adminTov.loadTovs)

  const [fields, setFields] = useFormFields({
    tov_date_range: option1Value
  })

  const showTovEmailModal = e => {
    if (fields.tov_date_range === option1Value) {
      setDateRange(option1)
    } else if (fields.tov_date_range === option2Value) {
      setDateRange(option2)
    }
    setTovEmailModal(true)
  }

  const closeTovEmailModal = () => {
    setTovEmailModal(false)
  }

  const handleDateFieldsChange = event => {
    setFields({
      [event.target.id]: event.target.value
    })
    showLabel();
  }

  const sendTovEmails = () => {
    let model = {
      tov_daterange: fields.tov_date_range
    }
    dispatch(sendTovEmailAction(model))
    setTovEmailModal(false)
  }

  useEffect(() => {
    setTovs([]);
    if (showLabel() === false) {
      setTovs(loadTovs.response.data);
    }

  }, [loadTovs])

  useEffect(() => {
    dispatch(loadAllTovReports(fields))
  }, [dispatch, fields])

  return (
    <>
      <div className='intro_text'>
        <span>TOV Data &nbsp;</span>
        <img
          src='/img/arrow_back.png'
          alt='Back arrow'
          width='12'
          height='16'
        />
      </div>
      <div className='font-custom form-margin-admin'>
        <div className='form-row justify-content-md-center'>
          <div>
            <div className='form-group'>
              <select
                className='form-control-sm'
                name='tov_date_range'
                id='tov_date_range'
                onChange={handleDateFieldsChange}
              >
                <option value={option1Value}>{option1}</option>
                <option value={option2Value}>{option2}</option>
              </select>
            </div>
          </div>
          <button
            className='btn btn-primary btn-sm btn-custom'
            style={{ marginTop: '0px' }}
            onClick={e => showTovEmailModal(e)}
          >
            Send Email to HCPs
          </button>
        </div>
      </div>
      {showLabel() && <Typography textAlign={"center"} color={"red"}>*  Contact SM team to pull a report of previously submitted data of selected date range </Typography>}
      <div className='font-custom form-margin-admin'>
        <Table
          keyField='id'
          data={tovs}
          columns={columns}
          isPaginationEnable={true}
          wrapperClasses='table-responsive'
        />
      </div>
      {/*Inform Tov Email modal view*/}
      <Modal
        id='tovEmail'
        show={tovEmailModal}
        onHide={closeTovEmailModal}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton />
        <Modal.Body style={{ textAlign: 'center' }}>
          <div
            style={{
              lineHeight: '30px',
              fontWeight: 'bold',
              fontSize: '16px'
            }}
          >
            <div style={{ color: 'red', fontSize: '20px' }}>
              Please confirm if you want to inform HCP's via emails.
            </div>
            <div>Tov Date Range : {dateRange}</div>
          </div>
          <div style={{ paddingTop: '2%', display: 'block' }}>
            <button
              onClick={sendTovEmails}
              name='sendEmailButton'
              className='btn btn-primary btn-custom'
            >
              Confirm
            </button>
            <button
              name='cancelButton'
              className='btn btn-primary btn-custom'
              onClick={closeTovEmailModal}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TovData
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Table,
  SectionHeader,
  SearchInputAdmin,
  SearchInputAdminNumber
} from '../../common'
import { useFormFields } from '../../../helpers/hooksFormInput'
import { loadTovReports } from '../../../stores/actions/admin/TovActions'
import {
  raiseDisputeAction,
  deleteDisputeAction,
  loadDisputeReports,
  loadResolvedDisputeReports,
  updateDisputeAction,
  deleteDisputeDocAction
} from '../../../stores/actions/admin/DisputeActions'
import { downloadDisputeDocument } from '../../../services/admin/DisputeService'

const ManageDispute = () => {
  const dispatch = useDispatch()

  let columns = [
    {
      dataField: 'id',
      text: 'Dispute Id',
      sort: true
    },
    {
      dataField: 'event_id',
      text: 'Event Id',
      sort: true
    },
    {
      dataField: 'event_name',
      text: 'Event Name',
      sort: true
    },
    {
      dataField: 'hcp_name',
      text: 'HCP Name',
      sort: true
    },
    {
      dataField: 'hcp_paidto',
      text: 'Paid To',
      sort: true
    },
    {
      dataField: 'created_at',
      text: 'Created at',
      sort: true
    },
    {
      dataField: 'Edit',
      text: 'Edit',
      formatter: (cell, row) => (
        <span>
          <button
            onClick={e => showEditDisputeModal(e, row)}
            style={{ background: 'none', border: 'none', marginRight: '10%' }}
          >
            <img
              src='/img/edit_product.png'
              alt='Edit Product'
              width='16'
              height='16'
            />
          </button>
        </span>
      )
    }
  ]
  let columnsForResolvedDisputes = [
    {
      dataField: 'id',
      text: 'Dispute Id',
      sort: true
    },
    {
      dataField: 'event_id',
      text: 'Event Id',
      sort: true
    },
    {
      dataField: 'event_name',
      text: 'Event Name',
      sort: true
    },
    {
      dataField: 'hcp_name',
      text: 'HCP Name',
      sort: true
    },
    {
      dataField: 'hcp_paidto',
      text: 'Paid To',
      sort: true
    },
    {
      dataField: 'updated_at',
      text: 'Updated at',
      sort: true
    },
    {
      dataField: 'More Details',
      text: 'More Details',
      formatter: (cell, row) => (
        <span>
          <button
            onClick={e => showResDisputeModal(e, row)}
            style={{ background: 'none', border: 'none', marginRight: '10%' }}
          >
            <VisibilityOutlinedIcon color="action" />
          </button>
        </span>
      )
    }
  ]
  const [disputes, setDisputes] = useState([])
  const [resolved_disputes, setResolvedDisputes] = useState([])
  const [disable, setDisable] = useState(true)
  const [showTovDiv, setShowTovDiv] = useState(false)
  const [showEditDispute, setShowEditDispute] = useState(false)
  const [raiseDisputeModal, setRaiseDisputeModal] = useState(false)
  const [resolvedDisputeModal, setResDisputeModal] = useState(false)
  const [deleteDisputeModal, setDeleteDisputeModal] = useState(false)
  const [docsArrayLength, setDocsArrayLength] = useState(0)
  const [uploadfile, setUploadfile] = useState('')
  const [filename, setFilename] = useState('')
  const [filetype, setFiletype] = useState('')

  const [disputeRow, setDisputeRow] = useState('')
  const [inactiveDispRow, setRow] = useState('')
  const [supportDoc, setSupportDoc] = useState(null)
  const [supportDocArray, setSupportDocArray] = useState(0)
  //Dispute Fields
  const [eventId, setEventId] = useState('')
  const [eventName, setEventName] = useState('')
  const [participationId, setParticipationId] = useState('')
  const [documents, setDocuments] = useState(null)

  const [fields, setFields, handleFieldsChange] = useFormFields({
    dispute_id: '',
    tov_id: '',
    registration: '',
    transport: '',
    hotel: '',
    travelAccomodation: '',
    honorarium: '',
    comment: '',
    status: 1
  })

  const showRaiseDisputeModal = (e, row) => {
    let ProductDetails = row
    setRaiseDisputeModal(true)
    setDisputeRow(ProductDetails)
  }

  const showResDisputeModal = (e, row) => {
    let ProductDetails = row
    setResDisputeModal(true)
    setRow(ProductDetails)
    setSupportDoc(row.documents)
    if (row.documents !== null) {
      if (!row.documents.length) {
        setSupportDocArray(0)
      } else {
        setSupportDocArray(1)
      }
    }
  }

  const showEditDisputeModal = (e, row) => {
    setShowEditDispute(true)
    setEventId(row.event_id)
    setEventName(row.event_name)
    setParticipationId(row.hcp_participation_id)
    setFields({
      dispute_id: row.id,
      tov_id: row.tov_id,
      registration: row.tov_registration === null ? 0 : row.tov_registration,
      transport: row.tov_transport === null ? 0 : row.tov_transport,
      hotel: row.tov_hotel === null ? 0 : row.tov_hotel,
      travelAccomodation: row.hcp_travel_accomodation === null ? 0 : row.hcp_travel_accomodation,
      honorarium: row.tov_honorarium === null ? 0 : row.tov_honorarium,
      comment: row.comment,
      status: row.status_id
    })
    setDocuments(row.documents)

    if (row.documents !== null) {
      if (!row.documents.length) {
        setDocsArrayLength(0)
      } else {
        setDocsArrayLength(1)
      }
    }
  }

  const handleDisputeCancelClick = () => {
    setShowEditDispute(false)
  }

  const closeRaiseDisputeModal = () => {
    setRaiseDisputeModal(false)
    setDisputeRow('')
  }

  const closeInactiveDisputeModal = () => {
    setResDisputeModal(false)
    setRow('')
  }

  const raiseDispute = () => {
    setRaiseDisputeModal(false)
    dispatch(raiseDisputeAction(disputeRow))
  }

  let columnsTov = [
    {
      dataField: 'Dispute',
      text: 'Dispute',
      formatter: (cell, row) => (
        <span>
          <button
            onClick={e => showRaiseDisputeModal(e, row)}
            type='button'
            className='btn btn-outline-primary btn-sm'
          >
            Raise Dispute
          </button>
        </span>
      )
    },
    {
      dataField: 'event_id',
      text: 'Event ID',
      sort: true
    },
    {
      dataField: 'event_date',
      text: 'Event Date',
      sort: true
    },
    {
      dataField: 'event_name',
      text: 'Event Name',
      sort: true
    },
    {
      dataField: 'hcp_name',
      text: 'HCP Name',
      sort: true
    },
    {
      dataField: 'hcp_paidto',
      text: 'Paid To',
      sort: true
    },
    {
      dataField: 'tov_registration',
      text: 'Registration',
      sort: true
    },
    {
      dataField: 'hcp_travel_accomodation',
      text: 'Travel and Accomodation',
      sort: true
    },
    {
      dataField: 'tov_honorarium',
      text: 'Honorarium',
      sort: true
    }
  ]

  const [tovs, setTovs] = useState([])

  const loadTovs = useSelector(state => state.adminTov.loadTovs)
  const loadDisputes = useSelector(state => state.adminDispute.loadDisputes)
  const loadResDisputes = useSelector(state => state.adminDispute.loadResDisputes)
  const deleteDispute = useSelector(state => state.adminDispute.deleteDispute)
  const createDispute = useSelector(state => state.adminDispute.createDispute)
  const editDispute = useSelector(state => state.adminDispute.updateDispute)

  useEffect(() => {
    setTovs(loadTovs.response.data)
  }, [loadTovs])

  useEffect(() => {
    setDisputes(loadDisputes.response.data);
  }, [loadDisputes])

  useEffect(() => {
    setResolvedDisputes(loadResDisputes.response.data);
  }, [loadResDisputes])

  useEffect(() => {
    setShowTovDiv(false)
    const payload = {
      tov_date_range: null
    }
    dispatch(loadDisputeReports())
    dispatch(loadTovReports(payload))
    dispatch(loadResolvedDisputeReports())
  }, [dispatch, createDispute, editDispute])

  useEffect(() => {
    const payload = {
      tov_date_range: null
    }
    dispatch(loadDisputeReports())
    dispatch(loadResolvedDisputeReports())
    dispatch(loadTovReports(payload))
  }, [dispatch, deleteDispute])

  const handleTovDivChange = () => {
    setShowTovDiv(true)
  }

  const handleCancelClick = () => {
    setShowTovDiv(false)
    const payload = {
      tov_date_range: null
    }
    dispatch(loadDisputeReports())
    dispatch(loadResolvedDisputeReports())
    dispatch(loadTovReports(payload))
  }

  // const showDeleteDisputeModal = (e, row) => {
  //   setDeleteDisputeModal(true)
  //   setFields({
  //     ...fields,
  //     id: row.id,
  //     event_name: row.event_name
  //   })
  // }

  const closeDeleteDisputeModal = () => {
    setDeleteDisputeModal(false)
  }

  const deactivateDispute = () => {
    dispatch(deleteDisputeAction(fields))
    setDeleteDisputeModal(false)
  }

  const validation = () => {
    const errors = {}
    errors.comment = fields.comment === '' ? 'comment is required' : ''
    return errors
  }

  const updateDispute = e => {
    e.preventDefault()
    let model = {
      dispute_id: fields.dispute_id,
      tov_id: fields.tov_id,
      registration: fields.registration,
      transport: fields.transport,
      hotel: fields.hotel,
      hcp_travel_accomodation: fields.travelAccomodation,
      honorarium: fields.honorarium,
      comment: fields.comment,
      status: fields.status,
      uploadfile: uploadfile,
      filename: filename,
      filetype: filetype
    }
    dispatch(updateDisputeAction(model))
    setShowEditDispute(false)
  }

  const onFileChange = e => {
    let reader = new FileReader()
    reader.onload = function () {
      setUploadfile(this.result)
      setFilename(e.target.files[0].name)
      setFiletype(e.target.files[0].type)
    }
    reader.readAsBinaryString(e.target.files[0])
    checkIfFormFieldsIsFilledProperly(fields)
  }

  const handleRadioChange = e => {
    setFields({
      ...fields,
      status: parseInt(e.target.value)
    })
  }

  const checkIfFormFieldsIsFilledProperly = formFields => {
    for (let objects in formFields) {
      if (formFields[objects] === '' || formFields[objects] === null) {
        return setDisable(true)
      }
    }
    return setDisable(false)
  }

  useEffect(() => {
    checkIfFormFieldsIsFilledProperly(fields)
  }, [fields])

  const downloadDocument = (e, file) => {
    e.preventDefault()
    downloadDisputeDocument(file)
  }

  const deleteDocument = (e, file) => {
    e.preventDefault()
    dispatch(deleteDisputeDocAction(file))
    let docs = documents.filter(d => d.id !== file.id)
    setDocuments(docs)
  }

  return (
    <>
      <div className='intro_text'>
        <span>Manage Dispute &nbsp;</span>
        <img
          src='/img/arrow_back.png'
          alt='Back arrow'
          width='12'
          height='16'
        />
      </div>
      {!showTovDiv ? (
        !showEditDispute ? (
          <>
            <div className='font-custom form-margin-admin'>
              <div className='row'>
                <div className='col-lg-4 col-sm-4 col-md-4 offset-4'>
                  <div className='col text-center'>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm btn-custom'
                      onClick={handleTovDivChange}
                    >
                      Create New Dispute
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='font-custom form-margin-admin'>
              <Table
                keyField='id'
                data={disputes}
                columns={columns}
                isPaginationEnable={true}
                wrapperClasses='table-responsive'
              />
            </div>
            {resolved_disputes.length > 0 ? <>
              <div className='intro_text_resolved_dispute_heading'>
                <span>Resolved Disputes &nbsp;</span>
                <img
                  src='/img/arrow_back.png'
                  alt='Back arrow'
                  width='12'
                  height='16'
                />
              </div>
              <div className='font-custom form-margin-admin'>
                <Table
                  keyField='id'
                  data={resolved_disputes}
                  columns={columnsForResolvedDisputes}
                  isPaginationEnable={true}
                  wrapperClasses='table-responsive'
                />
              </div>
              <Modal
                id='resolvedDispModal'
                show={resolvedDisputeModal}
                onHide={closeInactiveDisputeModal}
                backdrop='static'
                keyboard={false}
              >
                <Modal.Header closeButton />
                <Modal.Body style={{ textAlign: 'center' }}>
                  <div
                    style={{
                      lineHeight: '30px',
                      fontWeight: 'bold',
                      fontSize: '16px'
                    }}
                  >
                    <div style={{ color: 'red', fontSize: '20px' }}>
                      TOV Details for event:
                    </div>
                    <div>Event Name : {inactiveDispRow.event_name}</div>
                    <div>Total Registration ToV : {inactiveDispRow.tov_registration}</div>
                    <div>Total Travel and Accomodation ToV : {inactiveDispRow.hcp_travel_accomodation}</div>
                    <div>Total Honorarium : {inactiveDispRow.tov_honorarium}</div>
                  </div>
                  {supportDocArray === 0
                    ? 'No supporting document was uploaded for this Dispute at resolution'
                    : supportDoc.map((obj, index) => (
                      <div style={{ paddingTop: '2%', display: 'block' }}>
                        <div>{obj.file_name}</div>
                        <div>
                          <button
                            onClick={e => downloadDocument(e, obj)}
                            style={{
                              background: 'none',
                              border: 'none',
                              marginRight: '16px'
                            }}
                          >
                            <img src='/img/download.png' alt='Download file' width='16' height='16' />
                          </button>
                        </div>
                      </div>))}
                  <button
                    name='cancelButton'
                    className='btn btn-primary btn-custom'
                    onClick={closeInactiveDisputeModal}
                  >
                    Close
                  </button>
                </Modal.Body>
              </Modal>
            </> : ''}
            {/*delete dispute modal view*/}
            <Modal
              id='deleteDisputeModal'
              show={deleteDisputeModal}
              onHide={closeDeleteDisputeModal}
              backdrop='static'
              keyboard={false}
            >
              <Modal.Header closeButton />
              <Modal.Body style={{ textAlign: 'center' }}>
                <div
                  style={{
                    lineHeight: '30px',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                  <div style={{ color: 'red', fontSize: '20px' }}>
                    Please confirm if you want to delete this dispute.
                  </div>
                  <div>Dispute ID : {fields.id}</div>
                  <div>Event Name : {fields.event_name}</div>
                </div>
                <div style={{ paddingTop: '2%', display: 'block' }}>
                  <button
                    onClick={deactivateDispute}
                    name='deleteDisputeButton'
                    className='btn btn-primary btn-custom'
                  >
                    Confirm
                  </button>
                  <button
                    name='cancelButton'
                    className='btn btn-primary btn-custom'
                    onClick={closeDeleteDisputeModal}
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <div className='font-custom form-margin-admin'>
            <div className='row'>
              <SectionHeader label='Update Dispute' />
            </div>
            <Formik initialValues={fields} validate={validation}>
              {(errors, touched, handleBlur) => (
                <form
                  action=''
                  method='post'
                  name='updateDisputeForm'
                  onSubmit={updateDispute}
                >
                  <div className='row'>
                    <SearchInputAdmin
                      name='dispute_id'
                      id='dispute_id'
                      value={fields.dispute_id || ''}
                      label='Dispute ID'
                      disabled='disabled'
                    />
                    <SearchInputAdmin
                      name='event_id'
                      id='event_id'
                      value={eventId || ''}
                      label='Event ID'
                      disabled='disabled'
                    />
                    <SearchInputAdmin
                      name='event_name'
                      id='event_name'
                      value={eventName || ''}
                      label='Event Name'
                      disabled='disabled'
                    />
                    <SearchInputAdminNumber
                      name='registration'
                      id='registration'
                      value={fields.registration || ''}
                      label='TOV Registration'
                      onChange={handleFieldsChange}
                    />
                    <SearchInputAdminNumber
                      name='travelAccomodation'
                      id='travelAccomodation'
                      value={fields.travelAccomodation || ''}
                      label='TOV Travel and Accomodation'
                      onChange={handleFieldsChange}
                    />
                    <SearchInputAdminNumber
                      name='honorarium'
                      id='honorarium'
                      value={fields.honorarium || ''}
                      label='TOV Honorarium'
                      onChange={handleFieldsChange}
                    />
                    <SearchInputAdmin
                      name='comment'
                      id='comment'
                      value={fields.comment || ''}
                      label='Comment'
                      onChange={handleFieldsChange}
                      onBlur={handleBlur}
                      error={errors.comment}
                    />
                    <div className='col-sm-6 col-md-6 col-lg-6'>
                      <div className='form-row form-group'>
                        <label
                          className='col-lg-4 col-sm-4 col-md-4 col-form-label'
                          style={{ marginTop: '2%' }}
                          htmlFor='uploadfile'
                        >
                          <sup style={{ color: 'red' }}></sup>Dispute File
                        </label>
                        <input
                          type='file'
                          className='form-control col-lg-8 col-sm-8 col-md-8'
                          id='uploadfile'
                          name='uploadfile'
                          onChange={onFileChange}
                          onBlur={handleBlur}
                          style={{ color: '#424242cc' }}
                        />
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-6'></div>
                    <div className='col-sm-6 col-md-6 col-lg-6'>
                      <div className='form-row form-group'>
                        <div className='col-lg-12 col-sm-12 col-md-12'>
                          {docsArrayLength > 0
                            ? documents.map((obj, index) => (
                              <div className='d-flex justify-content-between ml-4 mr-4'>
                                <div>{obj.file_name}</div>
                                <div>
                                  <button
                                    onClick={e => downloadDocument(e, obj)}
                                    style={{
                                      background: 'none',
                                      border: 'none',
                                      marginRight: '16px'
                                    }}
                                  >
                                    <img
                                      src='/img/download.png'
                                      alt='Delete Product'
                                      width='16'
                                      height='16'
                                    />
                                  </button>
                                  <button
                                    onClick={e => deleteDocument(e, obj)}
                                    style={{
                                      background: 'none',
                                      border: 'none'
                                    }}
                                  >
                                    <img
                                      src='/img/delete.png'
                                      alt='Delete Product'
                                      width='16'
                                      height='16'
                                    />
                                  </button>
                                </div>
                              </div>
                            ))
                            : ''}
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='col text-center'>
                        <div>
                          <input
                            type='radio'
                            value='1'
                            name='status'
                            checked={fields.status === 1}
                            onChange={handleRadioChange}
                          />
                          <label className='form-check-label' htmlFor='status'>
                            Active
                          </label>
                          <input
                            type='radio'
                            value='4'
                            name='status'
                            checked={fields.status === 4}
                            onChange={handleRadioChange}
                          />
                          <label className='form-check-label' htmlFor='status'>
                            Resolved
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row addproductbutton'>
                    <button
                      disabled={disable}
                      type='submit'
                      name='update'
                      className='btn btn-primary btn-custom'
                    >
                      Update
                    </button>
                    <button
                      type='button'
                      name='Cancel'
                      className='btn btn-primary btn-custom'
                      onClick={handleDisputeCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )
      ) : (
        <>
          <div className='font-custom form-margin-admin'>
            <Table
              keyField='id'
              data={tovs}
              columns={columnsTov}
              isPaginationEnable={true}
              wrapperClasses='table-responsive'
            />
            <div className='col-lg-4 col-sm-4 col-md-4 offset-4'>
              <div className='col text-center'>
                <button
                  type='button'
                  name='Cancel'
                  className='btn btn-primary btn-sm btn-custom'
                  onClick={handleCancelClick}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
          {/*Raise dispute modal view*/}
          <Modal
            id='disputeModal'
            show={raiseDisputeModal}
            onHide={closeRaiseDisputeModal}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton />
            <Modal.Body style={{ textAlign: 'center' }}>
              <div
                style={{
                  lineHeight: '30px',
                  fontWeight: 'bold',
                  fontSize: '16px'
                }}
              >
                <div style={{ color: 'red', fontSize: '20px' }}>
                  Please confirm if you want to raise dispute for this event.
                </div>
                <div>Event Name : {disputeRow.event_name}</div>
                <div>Event Date : {disputeRow.event_date}</div>
              </div>
              <div style={{ paddingTop: '2%', display: 'block' }}>
                <button
                  onClick={raiseDispute}
                  name='raiseDisputeButton'
                  className='btn btn-primary btn-custom'
                >
                  Confirm
                </button>
                <button
                  name='cancelButton'
                  className='btn btn-primary btn-custom'
                  onClick={closeRaiseDisputeModal}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}
export default ManageDispute